import React from 'react';
import './Nav.css';

function Nav() {
  return (
    <div className="nav">
      <h2>Shoppies</h2>
    </div>
  );
};

export default Nav;